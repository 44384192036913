import React from "react"
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";

// @ts-ignore
import reading from '../assets/Oss-FB-3.png';

// @ts-ignore
import styles from './404.module.scss';

export default ({ children }) => (
    <SiteLayout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Oss - Oida...</title>
            <link rel="canonical" href="http://oss.no/" />
        </Helmet>

        <div className={styles.container}>
            <div style={{ overflow: "hidden", marginLeft: "auto", marginRight: "auto", width: "auto", maxWidth: 900 }}>
                <img src={reading} alt="En som leser" className={styles.image} width="auto"></img>
            </div>
            <div className={styles.subheader}>
                Oida.. Den siden finnes tydeligvis ikke.
            </div>
        </div>

    </SiteLayout>
)
